<route>
  {
    "meta": {
      "permission": [
        "base_config.view_origins"
      ]
    }
  }
</route>

<template>
  <div>
    <dialorigin v-model="dialog" :to-edit="toEdit" @done="reload = true" />
    <i-table
      :title="$t('customer_origin')"
      :headers="headers"
      app="base_config.origins"
      api="customerOrigin"
      :reload.sync="reload"
      @click:create="open()"
      @click:edit="open($event)"
    >
    </i-table>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import dialorigin from '../../components/customerOrigin/modal.vue'
export default {
  components: {
    dialorigin
  },
  created() {
    if (this.$store.getters['utils/getIsloading']) {
      this.$store.dispatch('utils/actionLoading')
    }
  },
  data() {
    return {
      reload: false,
      dialog: false,
      toEdit: null
    }
  },
  computed: {
    ...mapGetters({
      getPermissions: 'session/getPermissions',
      isAdmin: 'session/isAdmin'
    }),
    headers() {
      return [
        {
          text: this.$t('name'),
          value: 'name'
        },
        {
          text: this.$t('description'),
          value: 'description'
        },
        {
          text:
            this.currencyBtn.delete || this.currencyBtn.edit
              ? this.$t('action')
              : '',
          value:
            this.currencyBtn.delete || this.currencyBtn.edit ? 'actions' : ''
        }
      ]
    },
    currencyBtn() {
      return {
        delete:
          this.getPermissions([`base_config.delete_origins`]) || this.isAdmin,
        edit:
          this.getPermissions([`base_config.change_origins`]) || this.isAdmin
      }
    }
  },
  methods: {
    open(item) {
      this.toEdit = item === undefined ? null : item
      this.dialog = true
    }
  }
}
</script>

<template>
  <i-modal
    :title="$t(edit ? 'editing' : 'add', { value: $t('customer_origin') })"
    :value="value"
    :load="loading"
    @submit="submit"
    @input="$emit('input', $event)"
  >
    <v-row>
      <v-col cols="12">
        <ValidationProvider
          vid="name"
          :name="$tc('name', 1)"
          rules="required"
          v-slot="{ errors }"
        >
          <v-text-field
            name="name"
            :error-messages="errors[0]"
            v-model="origin.name"
            outlined
            color="secondary"
            :label="$tc('name', 1)"
          />
        </ValidationProvider>
      </v-col>
      <v-col cols="12">
        <ValidationProvider
          vid="description"
          :name="$tc('description', 1)"
          rules="required"
          v-slot="{ errors }"
        >
          <v-textarea
            name="description"
            :error-messages="errors[0]"
            v-model="origin.description"
            outlined
            color="secondary"
            :label="$tc('description', 1)"
          />
        </ValidationProvider>
      </v-col>
    </v-row>
  </i-modal>
</template>
<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: false
    },
    toEdit: {
      type: [Object],
      default: () => null
    }
  },
  data() {
    return {
      isLoading: false,
      loading: false,
      origin: {
        name: '',
        description: ''
      },
      edit: false
    }
  },
  watch: {
    value: {
      immediate: true,
      handler(val) {
        if (val) {
          this.edit = this.toEdit !== null
          this.origin =
            this.toEdit !== null
              ? { ...this.toEdit }
              : { name: '', description: '' }
        }
      }
    }
  },
  methods: {
    async submit() {
      if (!this.loading) {
        try {
          this.loading = true
          const origin = !this.edit
            ? await this.$api.customerOrigin.create({ form: this.origin })
            : await this.$api.customerOrigin.edit({
                pk: this.origin.pk,
                form: this.origin
              })
          this.$emit('done', origin.data)
          this.$emit('input', false)
          this.$toast.success(
            `${this.$t('customer_origin')} ${this.$tc(
              this.edit ? 'edited' : 'created',
              1
            )}`
          )
        } finally {
          this.loading = false
        }
      }
    }
  }
}
</script>
